.master-card {
    border: unset;
    transition: background-color 0.15s ease-in-out;
}

.master-service-card.active .card {
    border: 1px solid #000000;
}

.master-service-card {
    padding-right: 0;
    padding-left: 0;
}

.master-service-card .card {
    cursor: pointer;
}