@import '@coreui/coreui/dist/css/coreui.min.css';
@import 'react-calendar/dist/Calendar.css';


html {
  font-size: 14px;
  /* overflow-x: hidden; */
}

body {
  background-color: var(--tg-theme-bg-color);
  color: #000000;
  overflow-x: hidden;
}

/* .row {
  margin-left: 0;
  margin-right: 0;
} */

/* .container-fluid {
  padding: 0;
} */

.tg-text {
  color: var(--tg-theme-text-color);
}

.tg-text-reverce {
  color: var(--tg-theme-bg-color);
}

.tg-border {
  border-color: var(--tg-theme-text-color);
}

.tg-border-reverce {
  border-color: var(--tg-theme-text-color);
}

.tg-background {
  background-color: var(--tg-theme-bg-color);

}

.tg-background-reverce {
  background-color: var(--tg-theme-text-color);
}

.tg-btn-text {
  color: var(--tg-theme-text-color);
}

.tg-icon {
  stroke: var(--tg-theme-text-color);
}

.black-color {
  color: #000000;
}

.white-color {
  color: #ffffff;
}

.black-bg {
  background-color: #000000;
}

.black-border {
  border-color: #000000;
}

.card {
  position: relative;
}

.btn.btn-outline-dark {
  background-color: var(--tg-theme-text-color);
  border-color: var(--tg-theme-text-color);
  color: var(--tg-theme-secondary-bg-color);
}

.btn.btn-outline-dark.active {
  background-color: var(--tg-theme-secondary-bg-color);
  border-color: var(--tg-theme-secondary-bg-color);
  color: var(--tg-theme-text-color);
}

.page:not(.choose-city-page) {
  padding-right: 0;
  padding-left: 0;
}

.card-image-wrapper {
  position: relative;
  flex-basis: 150px;
  flex-shrink: 0;
}

.card-image-wrapper::before {
  content: '';
  display: block;
  padding-top: 56%;
}

.card-image {
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.card-user-image {
  object-fit: contain;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}


.accordion-button:not(.collapsed) .card {
  background-color: #000000;
}

.carousel-image-wrapper {
  position: relative;
  width: 100%;
}

.carousel-image-wrapper::before {
  content: '';
  display: block;
  padding-top: 56%;
}

.carousel-image {
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.custom-offcanvas {
  color: var(--cui-heading-color);
  padding: 1rem;
  border-radius: 10px;
  bottom: calc(env(safe-area-inset-bottom) + 5.5rem);
  margin-top: 1rem;
  box-shadow: 0 19px 38px rgb(56 56 104 / 15%);
  z-index: 6;
  border: unset;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.custom-offcanvas.offcanvas-bottom {
  height: auto;
}

.custom-offcanvas.show, .custom-offcanvas.showing {
  position: sticky;
}

.custom-offcanvas .offcanvas-header, .custom-offcanvas .offcanvas-body {
  padding-left: 0;
  padding-right: 0;
  /* padding-bottom: 0; */
}

.preloader svg, .preloader circle  {
  stroke: var(--tg-theme-text-color);
}

.loader svg, .loader circle {
  stroke: var(--tg-theme-bg-color);
}

.react-calendar {
  width: 450px;
}

.react-calendar__tile:disabled,
.react-calendar__navigation button:disabled {
  background-color: var(--cui-gray-200);
}

.react-calendar__tile--active,.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background-color: #000000;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(0,0,0,0.25);
}

.logo-wrapper {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  z-index: 2;
}

.logo-wrapper:before {
  content: '';
  position: absolute;
  z-index: 1;
  width: 112%;
  height: 112%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #000000;
  border-radius: 50%;
}

.icon-wrapper {
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 15px;
  background-color: var(--cui-gray-300);
}

.icon-wrapper.small {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  background-color: unset;
}

.icon-wrapper svg {
  position: absolute;
  width: 75%;
  height: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-wrapper.small svg {
  position: absolute;
  width: 75%;
  height: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-label {
  color: var(--tg-theme-text-color);
}